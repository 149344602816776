import React, { useState } from 'react';
import './App.css';
import Products from './Products';
import Cart from './Cart';
import {Headerfile} from './Headerfile'

const PAGE_PRODUCTS = 'products';
const PAGE_CART = 'cart';


function App()
{
  
  const [cart, setCart] = useState([]);
  const [page, setPage] = useState(PAGE_PRODUCTS);

  const navigateTo = (nextPage) => {
    setPage(nextPage);
  };

   const getCartTotal = () => {
     return cart.reduce(
      (sum, { quantity }) => sum + quantity,
       0
     );
  };



  return(
    <div>

        {/* <div id='wishlist'> */}
        <button onClick={() => navigateTo(PAGE_CART)}>
           Go to cart ({getCartTotal()})
         </button>

        {/* </div> */}

         <button onClick={() => navigateTo(PAGE_PRODUCTS)}>
          View Products
         </button>
    
       {page === PAGE_PRODUCTS && (
        <Products cart={cart} setCart={setCart} />
       )}
       {page === PAGE_CART && (
        <Cart cart={cart} setCart={setCart} />
       )}
  {/* return (
    <div>
  <Profile />*/}
    </div>
  ); 
}

export default App;
