import React from 'react'
import { Mainlogo } from './Mainlogo';
import {TopBar} from './TopBar';


export const Headerfile = () => {
    return (
        <>
    <header className="header">
      <TopBar/>
      
      <Mainlogo title="Discover Latest Courses on" name="React"/>
      </header>
            
        </>
    )
}
