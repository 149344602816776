import React from 'react'
import {Profile} from './Profile'
import { Wishlist } from './Wishlist';
export const TopBar = () => {
    return (
        <div id ="main-bar">

            <div id="logo"></div>
            <div id="courses"><b>COURSES</b></div> 
            
            <Wishlist title="MY WISHLIST"/>

            <div id="cart"></div>
            <div id="profile"></div>
            
        </div>
    )
}
