import React from 'react';
import { Mainlogo } from './Mainlogo';
import {TopBar} from './TopBar';

export default function Cart({ cart, setCart }) {
  const getTotalSum = () => {
    return cart.reduce(
      (sum, { costPrice, quantity }) => sum + costPrice * quantity,
      0
    );
  };

  const clearCart = () => {
    setCart([]);
  };

  const setQuantity = (product, amount) => {
    const newCart = [...cart];
    newCart.find(
      (item) => item.Cname === product.Cname
    ).quantity = amount;
    setCart(newCart);
  };

  const removeFromCart = (productToRemove) => {
    setCart(
      cart.filter((product) => product !== productToRemove)
    );
  };

  return (
    <>

<header className="header">
      <TopBar/>
      
      <Mainlogo title="Shopping Cart" name=""/>
    </header>


      {/* ----- */}
      <div className="MainBody">
      <div className="card-class">
            <div className="container">

        {cart.map((props, idx) => (
          <div className="Barea">
          <div id="Bimage"></div>

          {/* BTitle start */}
          <div id="Btitle">
          <div id="Bname">
            {props.Cname}
          </div>
          <div id="Btag">{props.topic1}</div>
          <div id="Btag">{props.topic2}</div>
        </div>

      {/* Btitle closed */}
      <div id="Bauthor">Add to wishlist</div>
      <div id="Bwishlist"></div>
       <div id="Bcost-price">{props.costPrice}</div>
        <div id="Bdiscount">{props.discount}</div>
        <div id="next-page"></div>


            <button className="but" onClick={() => removeFromCart(props)}>
              Remove
            </button>
          </div>
        ))}
      </div>
      </div>
{/* ----------------- */}


      <div id="Cart">
      <div id="Cheading">Total Amount</div>

      <div id="Ctext">

        RS {getTotalSum()}/-
        <button id='b' className="but">
              CHECKOUT
            </button>

        </div>
      </div>

      </div>
    </>
  );
}
