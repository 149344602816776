import React, { useState } from 'react';
import {Headerfile} from './Headerfile'

export default function Products({ setCart, cart }) {
  const [products] = useState([
    {
      "Cname" : "Responsive Design Course XYZ How to design responsive templates",
      "authName": "Joseph Marie",
      "costPrice" : 563,
      "topic1" : "React" ,
      "topic2" : "React",
      "discount" : "923"

    },
    {
      "Cname" : "Response Design Course ABC How to design responsive templates",
      "authName": "Joseph Marie",
      "costPrice" : 200,
      "topic1" : "React" ,
      "topic2" : "React",
      "discount" : "923"

      
    },
    {
      "Cname" : "Javascript Design Course ABC How to design responsive templates",
      "authName": "Joseph Marie",
      "costPrice" : 200,
      "topic1" : "React" ,
      "topic2" : "React",
      "discount" : "923"
    },
    {
      "Cname" : "React Design Course ABC How to design responsive templates",
      "authName": "Joseph Marie",
      "costPrice" : 200,
      "topic1" : "React" ,
      "topic2" : "React",
      "discount" : "923"
    },
    {
      "Cname" : "Angular Design Course ABC How to design responsive templates",
      "authName": "Joseph Marie",
      "costPrice" : 200,
      "topic1" : "React" ,
      "topic2" : "React",
      "discount" : "923"
    }
  ]);

  const addToCart = (product) => {
    let newCart = [...cart];
    let itemInCart = newCart.find(
      (item) => product.Cname === item.Cname
    );
    if (itemInCart) {
      itemInCart.quantity++;
    } else {
      itemInCart = {
        ...product,
        quantity: 1,
      };
      newCart.push(itemInCart);
    }
    setCart(newCart);
  };

  return (
    <div>
            <Headerfile />

 <div className="MainBody">

   {/* ---------Body DIVISION------------ */}
              <div id="BodyMenu">

              <div id="bmenubar">
                <div id="btext">
                    ALL COURSES
                    </div>
                
                {/* <SortBar id="Course Price"/> */}
                 <div>
                 {/* <label>CoursePrice:</label> */}
                  <select>
                    <option>Course price</option>
                    <option>low to high</option>
                    <option>High to low</option>
                         
                  </select>
                 </div>

           {/* bmenubar closed */}
              </div>
           
           {/* --------CARDS-------- */}
      <div className="card-class">
            <div className="container">
            {products.map((props)=>(
                <>
                {/* <PrintItems{...data}/> */}
                
                <div className="Barea">
                <div id="Bimage"></div>

                {/* BTitle start */}
                <div id="Btitle">
                <div id="Bname">
                  {props.Cname}
                </div>
                <div id="Btag">{props.topic1}</div>
                <div id="Btag">{props.topic2}</div>
              </div>

            {/* Btitle closed */}
            <div id="Bauthor">{props.authName}</div>
            <div id="Bwishlist"></div>
             <div id="Bcost-price">{props.costPrice}</div>
              <div id="Bdiscount">{props.discount}</div>
              {/* <AddToCart/> */}
              <button className="but" onClick={() => addToCart(props)}>Addtocart</button>
              <div id="next-page"></div>
    
           </div>
           {/* Barea closed */}
           </>
            ))}

     {/* bodymenu closed */}
        </div>


        </div>


        {/* ---------card-class closed---------- */}
        </div>

{/* ------------First left half done-------------
----------------Second right half start ---------*/}

        <div id="sideSearch">
            <div id="search-area">
            <input id="search" placeholder="search here"/>
            <div id="search-icon"></div>
            
            </div>
            <div id="Cart">
                <div id="Cheading">YOUR CART DETAILS</div>
                <div id="Cdetails">
                    your cart is empty right now.Please add courses in the cart from the list.

                </div>
                <div id="TotalAmount">
                    <div id="Ctext">Total Cart Value</div>
                    <div id="total">RS 0/-</div>
                </div>
            </div>
            
        </div>
      {/*----------------Second right half end ---------*/}


{/* --------main body closed------- */}
    </div>
    </div>
  );
}
